.sidebarmenu-linkitem {
  --background: "none";
}

.sidebarmenu {
  --min-width: 300px;
}

@media (max-width: 767.98px) {
  .sidebarmenu-linkitem {
    --min-height: 44px;
  }
}

.toolbar {
  --background: linear-gradient(#0460a9, #012169);
}

.toolbar-noshadow {
  --background: #0460a9;
}

.header-noshadow:after {
  background-image: none;
}

.toolbar-light {
  --background: white;
}

.content {
  --background: #ffffff;
}

.toolbar-title {
  color: #ffffff;
}

.toolbar-title-light {
  --color: #181818;
}

.split-pane {
  --side-max-width: 300px;
}

.with-print {
  display: none;
}

@media print {
  body {
    overflow: visible;
    position: relative;
  }

  .ion-page,
  .split-pane,
  .split-pane-main,
  .split-pane-md {
    overflow: visible;
    contain: none;
  }

  .with-print {
    display: inline;
  }
  .no-print {
    display: none;
    opacity: 0;
    width: 0;
  }
}

.transaction-list {
  padding-bottom: 0;
}
